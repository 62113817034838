<template>
  <div>
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Brands
    </div>
    <b-card class="userBannerAds">
      <b-skeleton-wrapper
        :loading="brandsLoading"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-row
          v-if="allBrands.length"
        >
          <b-col
            v-for="campaign in allBrands.slice(0, 1)"
            :key="campaign.name"
            class="profile"
          >
            <span
              class="lead collapse-title bannerAdsClass"
            >
              <b-media class="mediaBanner">
                <span class="d-block font-weight-bolder text-nowrap id text-truncate font-size-5">
                  {{ campaign.name }}
                </span>
              </b-media>
            </span>
          </b-col>
        </b-row>
        <div
          v-if="allBrands.length === 0 && brandsLoading === false"
          class="d-flex justify-content-center align-items-center campaign-text"
        >
          No Brands Found
          <b-button
            variant="primary"
            class="float-right ml-auto buttons"
            @click="viewBrands"
          >
            Add Brands
          </b-button>
        </div>
        <div
          v-else
          class="buttonlink"
        >
          <b-button
            variant="link"
            class="p-0"
            @click="viewBrands"
          >
            View All
          </b-button>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BMedia,
  BSkeleton,
  BSkeletonWrapper,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaType from '@/common/enums/mediaTypeEnum'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BMedia,
    BSkeleton,
    BSkeletonWrapper,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MediaType,
      allBrands: [],
      brandsLoading: false,
    }
  },
  async mounted() {
    await this.getUserBrands()
  },
  methods: {
    async getUserBrands() {
      this.brandsLoading = true
      const response = await this.$store.dispatch('brands/getAllBrandsByUserHasGroupIdAdmin', {
        userHasGroupId: this.$route.params.userId, limit: 5, offset: 1, searchQuery: '',
      })
      this.allBrands = response.data.data.results
      this.brandsLoading = false
    },
    viewBrands() {
      this.$router.push(`/users/${this.$route.params.userId}/user-brands`)
    },
  },
}
</script>
    <style scoped>
    .profile{
      border: none;
      margin-bottom: 1rem;
      box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 7px;
    }
    .mediaBanner{
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .switches {
      border-radius: 25px;
    }
    .id{
      font-size: 14px;
      letter-spacing: 0px;
      color: #414141;
      font-weight: 600;
      font-family: "Montserrat";
      padding-left: 15px;
      max-width: 220px;
    }
    .buttonlink{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
    }
    .campaign-text{
      font-size: 14px;
      letter-spacing: 0px;
      color: #414141;
      font-weight: 600;
      font-family: "Montserrat";
      padding-left: 15px;
    }
    .bannerAdsClass{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: 640px) {
      .buttons{
        padding: 6px 9px;
        font-size: 12px;
      }
      .campaign-text{
        font-size: 12px;
      }
    }
    @media (max-width: 640px) {
      .buttons{
        font-size: 10px;
      }
      .campaign-text{
        font-size: 10px;
        padding-left: 0px
      }
    }
    .userBannerAds{
      min-height: 105px !important;
    }
    </style>
