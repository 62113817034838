<template>
  <div>
    <b-card
      v-if="isAgency === null"
    >
      <b-skeleton
        animation="fade"
        width="85%"
      />
      <b-skeleton
        animation="fade"
        width="55%"
      />
      <b-skeleton
        animation="fade"
        width="70%"
      />
    </b-card>
    <b-row v-if="!isAgency && isAgency !== null">
      <b-col
        lg="12"
        md="12"
      >
        <b-card>
          <VenueDashboard />
        </b-card>
      </b-col>
    </b-row>

    <b-card
      v-if="isAgency && isAgency !== null"
    >
      <AgencyDashboard />
    </b-card>

    <b-col
      v-if="!isAgency && isAgency !== null"
      cols="12"
    >
      <b-card
        title="All Venues"
      >
        <AllVenues
          ref="allVenues"
        />
      </b-card>
    </b-col>

    <b-row class="match-height">
      <b-col
        v-if="isAgency !== null"
        lg="6"
        md="6"
      >
        <UserGroupsCard />
      </b-col>
      <b-col
        v-if="!isAgency && isAgency !== null"
        lg="6"
        md="6"
      >
        <UserBannerAdsCard />
      </b-col>
      <b-col
        v-if="isAgency && isAgency !== null"
        lg="6"
        md="6"
      >
        <UserBrandListCard />
      </b-col>
      <b-col
        v-if="isAgency !== null"
        lg="6"
        md="6"
      >
        <UserPmpAdsCard />
      </b-col>
      <b-col
        v-if="isAgency !== null"
        lg="6"
        md="6"
      >
        <UserMediaStorageCard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BSkeleton,
} from 'bootstrap-vue'
import UserGroupsCard from '@/components/admin/UserGroupsCard.vue'
import UserBannerAdsCard from '@/components/admin/UserBannerAdsCard.vue'
import UserPmpAdsCard from '@/components/admin/UserPmpAdsCard.vue'
import UserMediaStorageCard from '@/components/admin/UserMediaStorageCard.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import UserBrandListCard from '@/components/admin/UserBrandListCard.vue'
import VenueDashboard from '@/views/simiNetworkDashboard/VenueDashboard.vue'
import AgencyDashboard from '@/views/agencyDashboard/index.vue'
import AllVenues from '@/components/venues/AllVenues.vue'

export default {
  components: {
    UserMediaStorageCard,
    UserBannerAdsCard,
    UserPmpAdsCard,
    UserGroupsCard,
    UserBrandListCard,
    VenueDashboard,
    BRow,
    BCol,
    BCard,
    AgencyDashboard,
    BSkeleton,
    AllVenues,
  },
  data() {
    return { permissions: [], isAgency: null }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },

  async mounted() {
    this.permissions = await this.$store.dispatch('brands/checkIfGroupHasAgencyPermission', {
      userHasGroupId: this.$route.params.userId,
    })
    this.isAgency = this.permissions.some(feature => feature.slug === MemberPermissions.AGENCY)
  },
}
</script>
